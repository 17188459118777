<template>
    <div class="author-dishes" id="author-dishes">
        <div class="_container">
            <div class="shef">
                <h2 class="title">О шефе</h2>
                <div class="shef__container">
                    <div class="shef__body">
                        <div class="shef__text text_desktop" v-html="text"></div>
                    </div>
                    <div class="shef__img"><img src="@/assets/img/shef.jpg" alt="shef" /></div>
                    <div class="shef__text text_mobile" v-html="text_mobile"></div>
                </div>
            </div>
            <div class="author-dishes__container">
                <h2 class="title">{{ title }}</h2>
                <swiper
                    class="author-dishes__videos"
                    :modules="modules"
                    :slides-per-view="4"
                    :space-between="20"
                    navigation
                    :pagination="{ clickable: true }"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    :loop="true"
                    :breakpoints="{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                    }"
                    :autoplay="{ delay: timeSlide }"
                >
                    <swiper-slide class="author-dishes__video" v-for="video in videos" :key="video.id">
                        <video
                            width="100%"
                            height="100%"
                            loop
                            playsinline
                            muted
                            :poster="require(`@/assets/img/poster/${video.poster}`)"
                        >
                            <source :src="require(`@/assets/video/${video.src}`)" type="video/mp4" codecs="mp4" />
                        </video>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
export default {
    name: "AuthorDishes",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, A11y, Autoplay],
        };
    },
    data() {
        return {
            title: "Авторские блюда",
            videos: [
                { id: 1, src: "1.mp4", time: 13, poster: "1.png" },
                { id: 2, src: "2.mp4", time: 12, poster: "2.png" },
                { id: 3, src: "3.mp4", time: 12, poster: "3.png" },
                { id: 4, src: "4.mp4", time: 15, poster: "4.png" },
            ],
            timeSlide: 0,
            text: `
                <p>Как музыканты знают, какие ноты звучат друг с другом лучше, мы знаем, как 
                    сочетать продукты. Всё чаще люди приходят в рестораны ради шеф-поваров, 
                    чтобы «попробовать» их стиль. Знаете, этакий своеобразный концерт вкусов.</p>
                <strong>Алексей Чернов — бренд-шеф ресторанов группы T-family.</strong>
                <p>С раннего детства проводил лето у дедушки с бабушкой в татарской деревне, 
                    где его часто угощали традиционными татарскими угощениями. Так 
                    зародилась любовь к татарской кухне, которую сегодня шеф успешно 
                    транслируют в трёх ресторанах: Тюбетей, TATAR и ZAMAN.</p>
                <ul>
                    <li>Бренд-шеф группы компаний T-Family (ресторан Татар, Заман, Тюбетей)</li>
                    <li>Член Гильдии шеф-поваров РТ</li>
                    <li>Постоянный член жюри Регионального турнира молодых поваров имени Юнуса Ахметзянова</li>
                    <li>Прошел обучение в Академии кулинарного искусства «Эксклюзив»</li>
                    <li>Участник конкурса «Пир Поволжья», 2007 г., Серебро</li>
                    <li>Участник конкурса «Пир Экспо Рациональ» 2018г., Бронза</li>
                    <li>Участник конкурса имени Юнуса Ахметзнова 2016 г., Приз зрителей симпатий</li>
                </ul>
            `,
            text_mobile: `
                <ul>
                    <li>Бренд-шеф группы компаний T-Family (ресторан Татар, Заман, Тюбетей)</li>
                    <li>Член Гильдии шеф-поваров РТ</li>
                    <li>Постоянный член жюри Регионального турнира молодых поваров имени Юнуса Ахметзянова</li>
                    <li>Прошел обучение в Академии кулинарного искусства «Эксклюзив»</li>
                    <li>Участник конкурса «Пир Поволжья», 2007 г., Серебро</li>
                    <li>Участник конкурса «Пир Экспо Рациональ» 2018г., Бронза</li>
                    <li>Участник конкурса имени Юнуса Ахметзнова 2016 г., Приз зрителей симпатий</li>
                </ul>
            `,
        };
    },
    mounted() {
        for (let i = 0; i < this.videos.length; i++) {
            this.timeSlide = this.videos[i].time * 1000;
        }
        let videos = document.querySelectorAll(".author-dishes__video > video");
        function playVideos() {
            setTimeout(() => {
                videos[0].play(),
                    videos[3].load(),
                    (videos[3].style.filter = "brightness(0.5)"),
                    (videos[0].style.filter = "brightness(1)");
            }, 0);
            setTimeout(() => {
                videos[1].play(),
                    videos[0].load(),
                    (videos[0].style.filter = "brightness(0.5)"),
                    (videos[1].style.filter = "brightness(1)");
            }, 13 * 1000);
            setTimeout(() => {
                videos[2].play(),
                    videos[1].load(),
                    (videos[0].style.filter = "brightness(0.5)"),
                    (videos[1].style.filter = "brightness(0.5)"),
                    (videos[2].style.filter = "brightness(1)");
            }, 25 * 1000);
            setTimeout(() => {
                videos[3].play(),
                    videos[2].load(),
                    (videos[0].style.filter = "brightness(0.5)"),
                    (videos[1].style.filter = "brightness(0.5)"),
                    (videos[2].style.filter = "brightness(0.5)"),
                    (videos[3].style.filter = "brightness(1)");
            }, 37 * 1000);
        }
        if (window.innerWidth < 768) {
            for (let j = 0; j < videos.length; j++) {
                videos[j].play();
            }
        } else {
            playVideos();
            setInterval(() => {
                playVideos();
            }, 52 * 1000);
        }
    },
};
</script>

<style lang="scss">
.author-dishes {
    .shef {
        padding-bottom: 100px;
        .text_mobile ul {
            display: none;
        }
        @media (max-width: $ms3) {
            padding-bottom: 60px;
            .text_mobile ul {
                display: block;
            }
            .text_desktop ul {
                display: none;
            }
            .text_desktop p {
                white-space: normal;
            }
        }
        &__container {
            display: flex;
            justify-content: space-between;
            @media (max-width: $ms3) {
                flex-direction: column;
            }
        }

        &__body {
            padding: 0px 20px 0px 0px;
            @media (max-width: $ms3) {
                padding: 0;
            }
        }

        &__text {
            font-size: 18px;
            line-height: 28px;
            color: #000;
            margin-top: 40px;
            @media (max-width: $ms3) {
                font-size: 14px;
                line-height: 24px;
                margin-top: 20px;
            }
            p {
                margin-bottom: 40px;
                white-space: pre-line;
                @media (max-width: $ms3) {
                    margin-bottom: 20px;
                }
            }
            ul {
                @media (max-width: $ms3) {
                    display: none;
                }
                li {
                    font-size: 12px;
                    line-height: 16px;
                    color: #0d0d0d;
                    position: relative;
                    padding-left: 32px;
                    display: flex;
                    align-items: center;
                    margin-top: 15px;
                    &::before {
                        content: url("~@/assets/img/svg/tulpan-li.svg");
                        position: absolute;
                        left: 0;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        &__img {
            width: 100%;
            max-width: 456px;
            height: 580px;
            border-radius: 8px;
            overflow: hidden;
            margin-top: 10px;
            @media (max-width: $ms3) {
                max-width: 100%;
                height: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    background-color: #e5eefa;
    padding-top: 60px;
    padding-bottom: 80px;
    .title {
        text-align: left;
    }
    @media (max-width: $ms3) {
        padding-top: 40px;
        padding-bottom: 100px;
    }

    &__videos {
        display: flex;
        height: 570px;
        @media (max-width: $ms3) {
            height: 554px;
        }
    }

    &__video {
        border-radius: 8px;
        overflow: hidden;
        background: #fff;
        margin-top: 30px;
        cursor: pointer;
        @media (max-width: $ms3) {
            margin-top: 20px;
        }
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.5);
            @media (max-width: $ms3) {
                filter: brightness(1) !important;
            }
        }
    }
    .swiper .swiper-pagination {
        display: none;
        @media (max-width: $ms3) {
            display: flex;
        }
    }
    .swiper-pagination-bullet-active {
        background: #2a374b !important;
    }
    .swiper .swiper-pagination .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background: rgb(42, 55, 75, 0.2);
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: -50px;
        top: unset;
    }
}
</style>
